import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MoreSubjectButton from '../../CommonPage/MoreSubjectButton';
import Account1 from "../../Image/Dissertation_Subject_Page/History-P/History-1.webp";
import Account2 from "../../Image/Dissertation_Subject_Page/History-P/History-2.webp";
import Account3 from "../../Image/Dissertation_Subject_Page/History-P/History-3.webp";
import Account4 from "../../Image/Dissertation_Subject_Page/History-P/History-4.webp";
import '../../Page/Dissertation_Subject_Page/MainSubjectPage.css';
import Guarantees from '../LiteratureReview/Guarantees';
import FaqComponent from './FaqComponent';

function MathsSubjectPage() {
    const [showContent, setShowContent] = useState(false);
    const [showMoreContent, setShowMoreContent] = useState(false);
    const [showMoreContents, setShowMoreContents] = useState(false);
    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, []);
    const toggleContent = () => {
        setShowContent(!showContent)
    }
    const toggleMoreContent = () => {
        setShowMoreContent(!showMoreContent)
    }
    const toggleMoreContents = () => {
        setShowMoreContents(!showMoreContents)
    }
    return (
        <>
            <Helmet>
                <title>Mathematics Dissertation | Do My Mathematics Dissertation For Me</title>
                <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
                <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/mathematics/" />
            </Helmet>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Can I Pay Someone To Write My Math Dissertation?</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Yes, you can pay someone to write your math dissertation! Our expert writers specialize in advanced mathematical research and dissertation writing. We provide personalized support, ensuring that your dissertation is well-structured, thoroughly researched, and meets academic standards. From complex equations to detailed analysis, we handle every aspect with precision. Let us take the stress out of your math dissertation and help you achieve success. Contact us today for reliable, professional assistance.</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account1} alt="What is a Finance Dissertation Writing Service?" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Math Dissertation Topics Covered By Write My Dissertation For Me</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account2} alt="Finance Dissertation Help Service" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p><b>1. Algebra</b>: Our algebra math assistance services work with general algebraic expressions and modify them to get the desired outcomes. Therefore, if you're still having trouble solving your algebraic equations, don't hesitate to ask our specialists for online match dissertation help.</p>
                            <p><b>2. Arithmetic</b>: One of the most fundamental areas of mathematics is arithmetic. It is made up of fundamental operations including division, multiplication, subtraction, and addition. All of the issues in this subdiscipline are handled by our online math dissertation help.</p>
                            {
                                showContent && (
                                    <div className="more-content-div">
                                        <p><b>3. Geometry</b>: The forms, sizes, and volumes of various figure types are the subject of geometry. Trying to keep in mind every property and computation technique for every object might be daunting. So, if you need to finish your paper before the deadline, acquire online help for math assignments.</p>
                                        <p><b>4. Trigonometry</b>: Have you ever had trouble figuring out problems involving angles, triangle sides, and length and distance measurements? That's trigonometry, and trigonometry is a very difficult area of mathematics. However, since we have you covered with our online math dissertation help, you don't need to worry about it.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleContent}>{showContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Why Trust Our Math Experts?</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Write My Dissertation For Me experts are well-trained, learned individuals From Top global Universities with academic and Professional Qualifications. Therefore, They have several years of experience and knowledge, placing them competitively as the experts to rely on to write a dissertation that is well-researched and of top quality.</p>
                            {
                                showMoreContents && (
                                    <div className="more-content-div">
                                        <p>These Experts have also qualified at different academic levels in college, up to Masters and Ph.D. degrees. Therefore, they understand the level of quality required by university professors for Math dissertations and the level of research required to produce a well-written thesis.</p>
                                        <p>Our Global Pool of writers also comes from various math fields. Therefore, they can tackle any math dissertation topic, ranging from simple arithmetic to more complex calculus.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleMoreContents}>{showMoreContents ? "Read Less" : "Read More"}</button>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account3} alt="Finance Dissertation Help Services" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Steps to Hire Our Math Dissertation Helper in the USA</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account4} alt="Finance Dissertation Help" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p><b>1. Visit Our Website</b>: Navigate to our website to explore our math dissertation writing services tailored to your needs.</p>
                            <p><b>2. Fill Out the Order Form</b>: Provide essential details about your dissertation, including topic, requirements, and deadline.</p>
                            <p><b>3. Choose Your Writer</b>: Select from our experienced math dissertation writers based on their qualifications and expertise.</p>
                            {
                                showMoreContent && (
                                    <div className="more-content-div">
                                        <p><b>4. Make the Payment</b>: Complete the secure payment process to confirm your order.</p>
                                        <p><b>5. Receive Your Dissertation</b>: Get your completed dissertation on time, ready for submission!</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleMoreContent}>{showMoreContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <MoreSubjectButton />
            <Guarantees />
            <FaqComponent />
        </>
    )
}

export default MathsSubjectPage;