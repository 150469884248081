import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MoreSubjectButton from '../../CommonPage/MoreSubjectButton';
import Account1 from "../../Image/Dissertation_Subject_Page/History-P/History-1.webp";
import Account2 from "../../Image/Dissertation_Subject_Page/History-P/History-2.webp";
import Account3 from "../../Image/Dissertation_Subject_Page/History-P/History-3.webp";
import Account4 from "../../Image/Dissertation_Subject_Page/History-P/History-4.webp";
import '../../Page/Dissertation_Subject_Page/MainSubjectPage.css';
import Guarantees from '../LiteratureReview/Guarantees';
import FaqComponent from './FaqComponent';

function EnglishSubjectPage() {
    const [showContent, setShowContent] = useState(false);
    const [showMoreContent, setShowMoreContent] = useState(false);
    const [showMoreContents, setShowMoreContents] = useState(false);
    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, []);
    const toggleContent = () => {
        setShowContent(!showContent)
    }
    const toggleMoreContent = () => {
        setShowMoreContent(!showMoreContent)
    }
    const toggleMoreContents = () => {
        setShowMoreContents(!showMoreContents)
    }
    return (
        <>
            <Helmet>
                <title>English Dissertation | Do My English Dissertation For Me</title>
                <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
                <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
                <link rel="canonical" href=" https://www.writemydissertationforme.com/english/" />
            </Helmet>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Get English Dissertation Help From Experts</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>As it does not involve any calculations or statistics like other subjects do, you might believe that writing an English dissertation is a simple task. But that is not true. To write a high-quality English dissertation suitable to grab the attention of lecturers, you must possess a flair for language and the appropriate writing style. Most importantly, all of this requires a lot of time. That is the major reason why we provide online English dissertation help in the USA.</p>
                            {
                                showMoreContents && (
                                    <div className="more-content-div">
                                        <p>At writemydissertationforme.com, we have a team of experienced native writers who can complete your assignments quickly. Basically, our English Dissertation Help services are made for students who want to write their dissertations well and get top grades in their classes. Even though we offer very competitive pricing and additional discounts, we never compromise on quality. So, you can trust us!</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleMoreContents}>{showMoreContents ? "Read Less" : "Read More"}</button>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account1} alt="What is a Finance Dissertation Writing Service?" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Get Dissertation Help from Us for All English Topics</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account2} alt="Finance Dissertation Help Service" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>At <Link to={"/"}>writemydissertationforme.com</Link>, we have experts to offer help with writing dissertations on all topics in English courses. The following are a few common subjects in which several students approach us for English dissertation writing help online.</p>
                            <p><b>1. Creative Writing</b>: In our team, we have numerous creative writers to prepare English dissertations on innovative research topics. Hire them to prepare high-quality English creative writing dissertations as per your needs.</p>
                            {
                                showContent && (
                                    <div className="more-content-div">
                                        <p><b>2. Composition Studies</b>: If you need English dissertation help in composition studies, contact us. For students at the college level, the experts in our platform will offer assistance in handling research and handling in this professional field and will also suggest valid sources for reference.</p>
                                        <p><b>3. Etymology</b>: This subject studies the origin of words and analyzes how the meanings have changed through history. Get in touch with us, if you need English dissertation writing help on Etymology research topics. Our subject professionals will provide the best assistance at a minimum price.</p>
                                        <p><b>4. Philology</b>: It is the historical study of a canonical and literary text in a language. This subject mainly analyzes the structure, and historical development of English literature and its relationship with other languages. Utilize our English dissertation writing service, if you struggle to compose a thesis on Philology research topics.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleContent}>{showContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>Why Do Students Need English Dissertation Help?</h2>
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>Students often seek English dissertation help for various reasons, including balancing part-time jobs with studies, especially when managing multiple assignments. Language fluency can be a challenge for those from non-English-speaking backgrounds. Dissertations are lengthy and require significant research, making time constraints another common issue. Students aim to score well, but lack of time and accuracy in their work leads them to seek assistance from professionals who ensure detailed and high-quality dissertations to meet deadlines.</p>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account3} alt="Finance Dissertation Help Services" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="first-subject-heading-and-para-section">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-12 subject-heading-para-div">
                            <h2>What is the importance of dissertation writing in a student's life?</h2>
                        </div>
                        <div className="col-md-6 subject-image-div">
                            <img src={Account4} alt="Finance Dissertation Help" />
                        </div>
                        <div className="col-md-6 subject-heading-para-div">
                            <p>One of the most essential academic pieces of writing that you will write is a Ph.D. dissertation. As a result, you must take caution and devote the appropriate time and attention to it, as a dissertation has a substantial impact on your final grades. A dissertation is the last piece of academic work which judges a student's ability in various sectors of higher education. The motivation for writing a dissertation and the criteria that influence its grade change depending on the core field in which it is written.</p>
                            {
                                showMoreContent && (
                                    <div className="more-content-div">
                                        <p><b>The Importance of Dissertations in Academics</b>: The majority of education takes place in a written format around the world; students must develop academic writing skills from the beginning of their formal education. As a result, the education system around the world focuses on teaching students how to write academically. Dissertation writing is now a vital element of education to execute this, requiring students to create an explanatory paper describing their study and outcome. It is a well-known reality that university students do have not enough time to handle their academic, educational, and professional lives. Having the ability to produce an amazing academic paper in a short amount of time becomes really difficult for students.</p>
                                        <p>The amount of pressure placed on a student is enormous. Furthermore, many students lack the necessary writing and research skills for their dissertation subjects. As a result, students will need English dissertation help to coordinate their classes, part-time jobs, tests, and assignment deadlines.</p>
                                    </div>
                                )
                            }
                            <button className="read-more-btn" onClick={toggleMoreContent}>{showMoreContent ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </section>
            <MoreSubjectButton />
            <Guarantees />
            <FaqComponent />
        </>
    )
}

export default EnglishSubjectPage;