import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link, NavLink } from 'react-router-dom';
import "./Footer.css";

function Footer() {
  return (
    <>
      <section className="footerSectionStartHere">
        <div className="container footerSectionLinksStartHere">
          <div className="row justify-content-center">
            <div className="col-md-3 dissertationChapersLinks">
              <h5><span>DISSERTATION CHAPTERS</span></h5>
              <div className="dissertation-links">
                <span><NavLink to={"/dissertationProposal"} className="href">Dissertation Proposal</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationAbstract"} className="href">Dissertation Abstract</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationIntroduction"} className="href">Dissertation Introduction</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationMethodology"} className="href">Dissertation Methodology</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationLiteratureReview"} className="href">Dissertation Literature Review</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationConclusion"} className="href">Dissertation Consclusion</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationBibliography"} className="href">Dissertation Bibliography</NavLink></span> |
                <span className='ms-1'><NavLink to={"/capstoneProjects"} className="href">Capstone Projets</NavLink></span>
              </div>
            </div>
            <div className="col-md-3 subjectsLinks">
              <h5><span>SUBJECTS</span></h5>
              <div className="subjects-links">
                <span><NavLink to={"/sampleSubjectPage"} className="href">Accounts Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Economics Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Finance Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">History Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Mathematics Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">IT Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Psychology Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Political Science Dissertation</NavLink></span> |
                <span className='ms-1'><NavLink to={"/sampleSubjectPage"} className="href">Engineering Dissertation</NavLink></span>
              </div>
            </div>
            <div className="col-md-3 siteLinks">
              <h5><span>SITE LINKS</span></h5>
              <div className="site-links">
                <span><NavLink to={"/aboutUs"} className="href">About Us</NavLink></span> |
                <span className='ms-1'><NavLink to={"/getQuotePage"} className="href">Contact Us</NavLink></span> |
                <span className='ms-1'><NavLink to={"/faq"} className="href">FAQs</NavLink></span> |
                <span className='ms-1'><NavLink to={"/siteMap"} className="href">Sitemap</NavLink></span> |
                <span className='ms-1'><NavLink to={"/testimonial"} className="href">Testinomials</NavLink></span> |
                <span className='ms-1'><NavLink to={"/blogPage"} className="href">Blogs</NavLink></span> |
                <span className='ms-1'><NavLink to={"/privacyPolicy"} className="href">Privacy Policy</NavLink></span> |
                <span className='ms-1'><NavLink to={"/termsAndConditions"} className="href">Terms &amp; Conditions</NavLink></span> |
                <span className='ms-1'><NavLink to={"/dissertationSubjects"} className="href">Subjects</NavLink></span>
              </div>
            </div>
            <div className="col-md-3 socialMediaLinks">
              <ul>
                <div>
                  <li><a href="https://www.linkedin.com/showcase/write-my-dissertation-for-me-2/?viewAsMember=true" className="href" target='_blank' rel='noopener noreferrer'><i className="bi bi-linkedin"><FaLinkedinIn /></i></a></li>
                  <li><a href="https://www.instagram.com/writemydissertationforme" className="href" target='_blank' rel='noopener noreferrer'><i className="bi bi-instagram"><FaInstagram /></i></a></li>
                </div>
                <div>
                  <li><a href="https://www.facebook.com/profile.php?id=100086694889149" className="href" target='_blank' rel='noopener noreferrer'><i className="bi bi-facebook"><FaFacebookF /></i></a></li>
                  <li><a href="https://twitter.com/dissertation41" className="href" target='_blank' rel='noopener noreferrer'><i className="bi bi-twitter"><FaTwitter /></i></a></li>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-md-12 lastFooterSection mt-3 pt-3">
            <p>Copyright &#169; 2022 <Link to={"/"} className="href">WRITEMYDISSERTATIONFORME.COM</Link> | All Rights Reserved. </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer;